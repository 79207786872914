Vue.asyncComponent('ak-menu', {
    name: 'ak-menu',
    data() {
        return {
            show: true
        }
    },
    props: {
        items: {
            type: Array,
            required: false,
            default: () => {},
        }
    },
    computed: {
        isSubMenu() {
            let isSubMenu = false;
            let parent = this.$parent;
            
            while (parent) {
                if (parent && parent.$options.name == this.$options.name) {
                    isSubMenu = true;
                }

                parent = parent.$parent;
            }
            
            return isSubMenu;
        }
    },
    methods: {
        onClickReturn() {
            this.$emit("ak-click-return");
            this.show = false;
        }
    },
    mounted() {
        this.show = !this.isSubMenu;
    }
}, 'wrappers/ak-menu.html');