import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-select', {
	name: 'ak-select',
	mixins: [FormElementMixin],
    props: {
        selectOptions: {
            type: Array,
            required: false,
            default: () => []
        },
        allowMultiple: {
            type: Boolean|Number,
            required: false,
            default: false
        }
    },
    computed: {
        multiple() {
            return this.allowMultiple == 1;
        },
        selectItems() {
            let formOptions = [];
			const options = this.selectOptions;
			options.forEach((element) => {
                formOptions.push({
                    value: element.value,
                    text: element.label
                })
            });
            return formOptions;
        }
	}
}, 'form/controls/ak-select.html');