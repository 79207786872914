import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-slug', {
    mixins: [FormElementMixin],
    methods: {
        refreshSlug(e) {
            e.preventDefault();
            this.$emit('refreshClick');
        }
    }
}, 'form/controls/ak-slug.html');