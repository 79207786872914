import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-upload', {
    mixins: [FormElementMixin],
    props: {
        baseUri: {
            type: String,
            required: false,
            default: ''
        },
        extensions: {
            type: String,
            required: true,
        },
        mimeTypes: {
            type: String,
            required: true,
        },
        size: {
            type: String | Number,
            required: true,
        },
        multiple: {
            type: String | Number,
            required: false,
            default: false
        },
        allowDirectory: {
            type: Boolean,
            required: false,
            default: false
        },
        maximumNumberOfFiles: {
            type: String | Number,
            required: false
        },
        cta: {
            type: String,
            required: false,
            default()  {
                return this.multiple ? "Drop uw bestanden hier" : "Drop uw bestand hier";
            }
        }
    },
    data: function () {
        return {
            uniqueId: Math.floor(Math.random() * 100),
            files: [],
            outputFiles: [],
            message: null,
            chunkData: {
                action: this.baseUri + '/upload',
                minSize: 0,
                maxActive: 3,
                maxRetries: 5,

                startBody: {
                    widgetId: this.$attrs.id
                },
                finishBody:{}
            }
        }
    },
    watch: {
        // check if an upload is done if so we return the outputFiles
        files: {
            handler(newFiles, oldFiles) {
                let files = [];
                newFiles.forEach((newFile, key) => {
                    // format the output to ower linking
                    if(newFile.originalFilename) {
                        files.push(newFile);
                    } else {
                        files.push(this.formatFile(newFile));
                    }
                });

                this.outputFiles = files;
            },
            deep: true
        },
        outputFiles(newOutputFiles, oldOutputFiles) {
            let uploadedFiles = [];

            // we only push the files that have been fully uploaded
            newOutputFiles.forEach((file, key) => {
                if(file.downloadPath) {
                    uploadedFiles.push(file);
                }
            });

            // call event so we push the files
            this.currentValue = uploadedFiles;
        },
        message(message) {
            console.log(message);

            if (message) {
                setTimeout(() => {this.message = null}, 5000);
            }

            return message
        }
    },
    computed: {
        // the file objects we use for display
        currentFiles() {
            let out = [];

            this.files.forEach((newFile, key) => {
                out[key] = this.formatFile(newFile);
            });

            return $out;
        }
    },
    methods: {
        /**
         * Has changed
         * @param  Object|undefined   newFile   Read only
         * @param  Object|undefined   oldFile   Read only
         * @return undefined
         */
        inputFile: function (newFile, oldFile) {
            // if we have a new file we automaticly trigger the upload
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }
        },
        /**
         * Pretreatment
         * @param  Object|undefined   newFile   Read and write
         * @param  Object|undefined   oldFile   Read only
         * @param  Function           prevent   Prevent changing
         * @return undefined
         */
        inputFilter: function (newFile, oldFile, prevent) {
            this.internalError = null;

            // if we don't have a new file we do nothing
            if(! newFile) {
                return;
            }

            if (newFile && !oldFile) {
                // Before adding a file
                // Filter system files or hide files
                if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                    return prevent()
                }
            }

            if(this.size && newFile.size > this.size) {
                this.message = 'Uw bestand mag niet groter zijn dan ' + (this.size / 1048576).toFixed(0) + 'MB';
                return prevent();
            }

            // filter out dragged files with the wrong mime type
            if(this.mimeTypes.indexOf(newFile.type) === -1 || newFile.type === "") {
                return prevent();
            }

            // Create a blob field
            newFile.blob = ''
            let URL = window.URL || window.webkitURL
            if (URL && URL.createObjectURL) {
                newFile.blob = URL.createObjectURL(newFile.file)
            }
        },
        /**
         * Remove an existing file
         * @param file
         */
        remove(currentFile) {
            // find the current file in the upload plugins file
            let uploadedFile = this.files.find(file => file.name == currentFile.fileName);

            // // if we find a file we remove it
            if(uploadedFile) {
                this.$refs.upload.remove(uploadedFile)
            } else {
                let keyToDelete;
                // if we diden't find the file in the uploadedFiles
                // we remove the file from the output
                this.outputFiles.forEach((outputFile, key) => {
                    if(outputFile == currentFile) keyToDelete = key;
                });

                // we remove the file in files the outputFiles will follow & the key is the same
                this.files.splice(keyToDelete, 1);
            }
        },
        /**
         * Return a formated size string
         * @returns {string}
         */
        maxFileSizeString() {
            if(this.size < 1000) {
                return this.size + 'B';
            }
            if(this.size >= 1000 && this.size < 1000000) {
                return this.size /1000 + 'KB';
            }
            if(this.size >= 1000000 && this.size < 1000000000) {
                return this.size /1000 + 'MB';
            }

        },
        /**
         * Translate the error codes to an error message
         *
         * @param error
         * @returns {*}
         */
        translateFileError(error) {
            switch(error) {
                case "size":
                    return this.$t('Het bestand moet kleiner zijn dan @size', {size: this.maxFileSizeString()});
                case "extension":
                    return this.$t('Het bestand moet de volgende extensie(s) hebben @extensions', {extensions: this.extensions});
                case "denied":
                    return this.$t('Het bestand is niet toegestaan');
                default:
                    return this.$t('Er ging iets mis tijdens het uploaden');

            }
        },
        /**
         *
         * @param file
         * @returns {boolean}
         */
        showImagePreview(file) {
            return file.type == 'image';
        },
        /**
         *
         * @param file
         * @returns {string}
         */
        downloadPath(file) {
            return "'" + this.baseUri + file.downloadPath + "'";
        },
        /**
         *
         * @param file
         * @returns {{originalFilename: (string|*), filePath: (*|methods.downloadPath), description: *, downloadPath: (*|methods.downloadPath), extension: *, fileName: (string|*), progress: (string|*), active: boolean, type: *}}
         */
        formatFile(file) {
            return {
                originalFilename: file.response.originalFilename ? file.response.originalFilename : file.name,
                description: file.response.description,
                downloadPath: file.response.downloadPath,
                isUploading: file.response.isUploading,
                filePath: file.response.filePath,
                fileName: file.response.fileName,
                extension: file.response.extension,
                progress: file.progress,
                active: file.active,
                type: file.response.type,
                id: file.id
            }
        }
    },
    mounted() {
        // check if we have a default value or values
        if(this.value && this.value.length) {
            // we will push each default value to the outputFiles
            this.value.forEach((value, key) => {
                // but first change to the format we need.
                this.outputFiles.push(value);
                this.files.push(value);
            });
        }
    }
}, 'form/controls/ak-upload.html');