import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-date-picker', {
    mixins: [FormElementMixin],
    data() {
        return {
            selectedDatepickerDate: null,
            selectedDate: null,
            datepicker: false,
        }
    },
    watch: {
        selectedDatepickerDate() {
            this.datepicker = false;
            this.selectedDate = this.selectedDatepickerDate ?
                    this.$moment(this.selectedDatepickerDate, 'YYYY-MM-DD').format('DD/MM/YYYY') :
                    null;
            this.currentValue = this.selectedDatepickerDate ?
                    this.$moment(this.selectedDate, 'DD/MM/YYYY').toISOString(true) :
                    null;
        }
    },
    methods: {
        changeSelectedDate() {
            if(this.selectedDate) {
                this.selectedDatepickerDate = this.$moment(this.selectedDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
                this.currentValue = this.$moment(this.selectedDate, 'DD/MM/YYYY').toISOString(true);
                this.$emit('blur');
            } else {
                this.selectedDatepickerDate = null;
                this.currentValue = null;
            }
        }
    },
    created() {
		if (this.value) {
            this.selectedDate = this.$moment(this.value).format('DD/MM/YYYY');
            this.selectedDatepickerDate = this.$moment(this.value).format('YYYY-MM-DD');
            this.currentValue = this.$moment(this.selectedDate).toISOString(true);
        }

    }
}, 'form/controls/ak-date-picker.html');