Vue.asyncComponent('ak-navigation', {
    props: {
        logoPath: {
            type: String,
            required: false,
            default: ''
        },
        assetUrl: {
            type: String,
            required: false,
            default: ''
        },
        notifications: {
            type: Number,
            required: true
        }
    },
    computed: {
        logo() {
            let logo = this.logoPath;
            
            if (!logo) logo = `${this.assetUrl}/img/logo.png`;

            return logo;
        }
    },
    methods: {
        clickMenuButton(e) {
            this.$emit('toggle-drawer', e);
        }
    }
}, 'wrappers/ak-navigation.html');