Vue.asyncComponent = function (name, options, templatePath = null, loadTemplate = true) {
    // Some components don't need to load the template, this optional prop allows us to bypass loading the template
    // This is useful for mixins and functional components
    if (!loadTemplate) return;

    // When no options are given we start with an empty object
    if (!options) options = {};

    // Componentnames could be in the form of a filename. Split the string and only use the last element.
    const componentName = name.toString().split('/').pop();

    // check if a specifik templatePath is given
    // else just look for the template with the component name in de root of the template folder
    const templateLocation = templatePath ?
                                `${templateUrl}${templatePath}` :
                                `${templateUrl}${componentName}.html`;

    let renderFailed = (resolve, error) => {
        console.log(error);
        resolve({ ...options, template: `<div><p>Cannot load component for ${componentName}</p><slot></slot></div>`})
    };

    // Load the component template from the baseUrl, show loading state after 200ms, show error state after 5000ms.
    this.component(componentName, () => {
        return {
            component: new Promise(async (resolve) => {
                try {
                    const response = await fetch(templateLocation);
                    if(response.ok) {
                        const result = await response.text();
                        resolve({ ...options, template: result })
                    } else {
                        renderFailed(resolve, response.statusText);
                    }
                } catch (error) {
                    renderFailed(resolve, error);
                }
            }),
            // While loading, the template is...
            loading: Vue.component('loader', {
                template: '<p>loading...</p>'
            }),
            // Upon error, the template will be replaced by...
            error: Vue.component('load-error', {
                template: '<p>error loading component</p>'
            }),
            // Delay, before we show the loading state...
            delay: 200,
            // We allow 5 seconds before we abort with a timeout error:
            timeout: 5000
        }
    })
}