import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-time-picker', {
    mixins: [FormElementMixin],
    props: {
        minStep: {
            type: Number,
            required: false,
            default: 1
        }
    },
    data() {
        return {
            timepicker: false,
            pickedTime: null,
            allowedMinutes: v => v % this.minStep === 0
        }
    },
    methods: {
        prependClicked() {
            this.timepicker = true;
        }
    },
    watch: {
        pickedTime: {
            handler(val) {
                this.currentValue = val;
            }
        }
    }
}, 'form/controls/ak-time-picker.html');