Vue.component('ak-widget', {
    functional: true,
    name: "AkWidget",
    props: {
        type: {
            type: String,
            required: true
        },
        contextType: {
            type: String,
            required: false
        },
        columnId: {
            type: String,
            required: false
        },
        objectId: {
            type: String,
            required: false
        },
        attributes: {
            type: Object,
            required: false,
            default: () => {}
        },
        callbacks: {
            type: Array,
            required: false,
            default: () => []
        },
        callbackUri: {
            type: String,
            required: false,
            default: () => window.location.toString()
        },
        paramsForCallback: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    render(createElement, { props, listeners, slots }) {

        let handlers = {};
        let tmp;
        let currentElement;
        let ref = props.type + '-' + props.attributes.id;

        for (const e in listeners) {
			tmp = listeners[e];

            if (props.callbacks.findIndex(x => x === e) === -1) {
                handlers[e] = tmp;
                continue;
            }

            handlers[e] = () => {
                // Execute callback here
                console.log('callback first. Use ', props.callbackUri);
                tmp();
			}
        }

        for (e in props.callbacks) {
            let callback = props.callbacks[e];
            // if no current listener is bind to the event
            if (handlers[callback] === undefined) {
                // we will use the callback logic
                handlers[callback] = async(e) => {
                    // Execute callback here
                    // console.log('callback first. Use ',props, callback, props.callbackUri);
                    const params = {
                        contextType: props.contextType,
                        widgetId: props.attributes.id,
                        objectId: props.objectId,
                        columnId: props.columnId,
                        event: callback,
                        value: e,
                        ...props.paramsForCallback
                    };
                    try {
                        const result = await AppKit.getApp().$get(`${props.callbackUri}widget-event`, params, null);
                        AppKit.getApp().$handleActions(result, currentElement.context.$refs[ref]);
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }

        currentElement = createElement(
            props.type,
            {
                // attrs: props.attributes,
                // merge attributes an all the props set on the widget
                props: {...props.attributes, ...props},
                ref: ref,
                on: handlers
            },
            slots().default
        );

        return currentElement;
    }
}, false);