Vue.asyncComponent('ak-data-table', {
    props: {
        definition: {
            type: String,
            required: true
        },
        bundle: {
            type: String,
            required: true
        },
        maxTableLength: {
            type: Number,
            required: false,
            default: 200
        },
        infiniteScroll: {
            type: Boolean,
            required: false,
            default: true
        },
        idsSelected: {
            type: Array | Object,
            required: false,
            default: () => { return [] }
        },
        mockData: {
            type: Boolean,
            required: false,
            default: false
        },
        mockDataObject: {
            type: Object,
            required: false,
            default: () => { }
        },
        relation: {
            type: String,
            default: '',
            required: false
        },
        query: {
            type: Object,
            default: () => {},
            required: false
        }
    },
    data() {
        return {
            title: '',
            descr: '',
            pageJumper: {},
            pageJumperWidgets: {},
            dataObjects: [],
            filters: {},
            filterEdit: null,
            showFilterDialog: false,
            columns: [],
            selectables: {},
            baseGridUri: null,
            selection: {
                allSelected: false,
                idsSelected: [],
                idsExcluded: []
            },
            searchEnabled: false,
            dataFetchParams: {
                page: 1,
                q: '',
                sortby: '',
                direction: ''
            },
            searchString: '',
            preventAutoRefresh: false,
            debounceTimeout: null,
            isUpdating: true,
            updatingMessage: null,
            idleWidgets: {},
            contextualPageJumperWidgets: {},
            sortingEnabled: null,
			search: {},
            dragParam: 0,
            refreshParam: 0,
            maxTableHeight: null,
            noResultString: 'Geen resultaten gevonden',
            exportDefinition: null,
            exportBundle: null,
        };
    },
    computed: {
        inViewPortKey() {
            return this.dataObjects[0].id + this.pageJumper.totalCount + this.tableHasBeenSorted + this.filters.applied.length + this.dragParam + this.refreshParam;
        },
        tableHasBeenSorted() {
            if (this.columns.findIndex(x => x.isSortedBy === true) === -1) return '0';
            else return '1';
        },
        showSearchDescription() {
            let show = false;
            if(! ('terms' in this.search)) {
                return show;
            }

            this.search.terms.forEach(item => {
                if(item.type != "text") {
                    show = true;
                }
            })

            return show;
        },
        searchDescription() {
            let description = '';
            this.search.terms.forEach(item => {
                description += this.$t(item.descr.text, this.$applyFormatting(item.descr.placeholders,item.descr.placeholderFormatting)) + ' ';
            })

            return description;
        },
    },
    methods: {
        async refresh() {
            //this.dataFetchParams.page = 1;

            const data = await this.fetchTableData();
            if (!data) return;

            this.columns = data.columns;
            this.pageJumper = data.pageJumper;
            this.title = data.title;
            this.descr = data.descr;
            this.searchEnabled = data.search.enabled;
            this.filters = data.filterUi;
            this.dataObjects = data.dataObjects;
            this.selectables = data.selectables;
            this.selection = data.selection;
            this.noResultString = data.noResultString || 'Geen resultaten gevonden';

            if(this.idsSelected) {
                this.selection.idsSelected = this.idsSelected;
            }

            this.idleWidgets = data.widgets.idle;
            this.contextualPageJumperWidgets = data.widgets.contextualPageJumper;
			this.sortingEnabled = data.canSortNow;
            this.search = data.search;
            this.calculateTableHeight();
        },
        async fetchTableData(loadingMessage, path) {
            if (this.maxTableLength <= this.dataObjects.length) return;
			this.preventAutoRefresh = true;

            if (!path) path = this.baseGridUri;
            if (!loadingMessage) loadingMessage = 'Loading data';

            this.tellIfUpdating(true, this.$t(loadingMessage));
            const params = this.addVariablesToDataFetchParams();
            
            // When we're mocking data, just return the data object we're using to mock.
            if (this.mockData) {
                console.log('MOCKING DATA --- DEV MODE')
                this.tellIfUpdating(false);
                this.preventAutoRefresh = false;
                return this.mockDataObject;
            } else {
                // Otherwise, just get the new data
                try {
                    const data = await this.$get(path, params);
                    return data;
                } catch (error) {
                    console.log(error);
                } finally {
                    this.tellIfUpdating(false);
                    this.preventAutoRefresh = false;
                }
            }            
        },
        async loadMore() {
            if (this.dataFetchParams.page === this.pageJumper.numberOfPages) return;

            this.preventAutoRefresh = true;
            this.dataFetchParams.page = this.dataFetchParams.page + 1;

            try {
                const data = await this.fetchTableData('Loading more');
                if (!data) return;
                this.dataObjects = [...this.dataObjects, ...data.dataObjects];
                this.pageJumper = data.pageJumper;
            } catch (error) {
                console.log(error);
            }

            this.preventAutoRefresh = false;
        },
        addVariablesToDataFetchParams() {
            let allParams = { ...this.dataFetchParams };

            if (this.selection.allSelected) {
                allParams = { ...this.dataFetchParams, 'selectall': 1 }
            } else if (this.selection.idsSelected.length > 0) {
                allParams = { ...this.dataFetchParams, 'selectids' : this.selection.idsSelected };
            }
            if (this.filters.applied && this.filters.applied.length !== 0) {
                this.filters.applied.forEach((filter, i) => {
                    allParams[`f${i}`] = filter.id;
                    allParams[`v${i}`] = filter.values;
                });
            }

            if(this.relation) {
                allParams['relation'] = this.relation;
            }

            allParams = { ...allParams, ...this.query }

            return allParams;
        },
        sortColumn(column) {
            if (!column.enableSortBy) return;
            if (this.dataFetchParams.sortby !== column.id) this.dataFetchParams.direction = '';
            this.dataFetchParams.page = 1;
            // First sort column ASC, then DESC and at the third click the sorting gets undone
            if (this.dataFetchParams.direction === '') {
                this.dataFetchParams.direction = 'ASC';
                this.dataFetchParams.sortby = column.id;
            }
            else if (this.dataFetchParams.direction === 'ASC') {
                this.dataFetchParams.direction = 'DESC';
                this.dataFetchParams.sortby = column.id;
            }
            else {
                this.dataFetchParams.direction = '';
                this.dataFetchParams.sortby = '';
            }
        },
        changePage(e) {
            this.dataFetchParams.page = e;
        },
        clearSearch() {
            this.searchString = '';
            this.dispatchSearch();
        },
        dispatchSearch() {
            this.dataFetchParams = {
                page: 1,
                q: '',
                sortby: '',
                direction: ''
            };
            this.dataFetchParams.q = this.searchString;
        },
        tellIfUpdating(flag, message) {
            // Update the loading state at the bottom of the page
            this.isUpdating = flag;
            this.updatingMessage = message;
        },
        addFilter: function (filter) {
            // Check if the filter is already active
            let index = -1;
            if(this.filterEdit) {
                index = this.filters.applied.findIndex(filter => filter == this.filterEdit);
            }

            this.showFilterDialog = false;

            let clearQuery = false;
            // check if the edited filter is a filter from the url query
            for (let i = 0; i < (this.filters.applied.length + 1); i++) {
                if (this.query && filter.id == this.query['f' + i]) {
                    clearQuery = true;
                }
            }

            // Filter not yet active? Just push. Is it active? Replace the current one.
            if (index === -1) {
                this.filters.applied.push(filter);
            } else {
                this.filters.applied.splice(index, 1, filter);
            }

            // Set page back to 1 and refresh data
            this.dataFetchParams.page = 1;
            if (clearQuery) {
                // if so clear the query
                this.$emit('clearQuery');
                this.$nextTick(() => this.refresh());
            } else {
                this.refresh();
            }
        },
        editFilter(filter, e) {
            this.filterEdit = filter;
            this.showFilterDialog = !this.showFilterDialog;
        },
        closeFilterDialog() {
            this.filterEdit = {},
            this.showFilterDialog = false;
        },
        deleteFilter(key) {
            const filter = this.filters.applied[key];

            // check if the filter we wan't to delete exists in the query
            for (let i = 0; i < (this.filters.applied.length + 1); i++) {
                if (this.query && filter.id == this.query['f' + i]) {
                    this.removeFilterFromQuery(i)
                }
            }

            this.filters.applied.splice(key, 1);
            this.dataFetchParams.page = 1;
            this.$nextTick(() => this.refresh());
        },
        removeFilterFromQuery(i) {
            const newQuery = {...this.query};
            delete newQuery['f' + i];
            Object.keys(newQuery).forEach(key => {
                if(key.includes('v' + i)) {
                    delete newQuery[key];
                }
            });

            this.$emit('updateQuery', newQuery);
        },
        searchLiteral(e, $q) {
            e.preventDefault();
            let string = '';

            // construct the literal search string
            this.search.terms.forEach((term,index) => {
                if(index > 0) {
                    string += ' ';
                }
                string +='"'+ term.text +'"'
            });

            this.searchString = string;
            this.dispatchSearch();
        },
        handleNew(data) {
            this.$emit('new', data);
        },
        handleEdit(data) {
            this.$emit('edit', data);
        },
        handleDelete(data) {
            // Make sure that a route call to itself just calls a refresh and doesn't reach Vue Router (where it will
            // throw an error)
            if (data.bundleId === this.bundle && data.definitionId == this.definition) {
                this.refresh();
            } else {
                // if not delete on itself then throw the delete event to parent
                this.$emit('delete', data);
            }
        },
		async submitNewSorting(e) {
			this.isUpdating = true;

			this.dragParam === 0 ? this.dragParam = 1 : this.dragParam = 0;

			// For a blue monday
			let newOrder = {
				instruction: e.instruction,
				startFromPage: this.infiniteScroll ? 1 : this.dataFetchParams.page,
				id: e.id
			};

			if (e.instruction === 'drag') newOrder.ids = this.dataObjects.map(x => x.id);

			const path = `${this.baseGridUri}sort-position`;
			const params = this.addVariablesToDataFetchParams();

			try {
				const result = await this.$post(path, newOrder, params);
				if (e.instruction !== 'drag') {
					if (this.infiniteScroll) this.dataFetchParams.page = 1;
					this.refresh();
				}
				if (result) this.$handleActions(result, this);
			} catch (error) {
				console.log(error);
			} finally {
				this.isUpdating = false;
			}
		},
        calculateTableHeight() {
            this.maxTableHeight = undefined;
            setTimeout(() => {
                const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
                const table = this.$refs.table.$el;
                const height = table.offsetHeight;
                const rect = table.getBoundingClientRect();
                let maxTableheight = vh - rect.top;

                const footer = document.querySelector('[data-footer]');
                const bottom = this.$refs.bottom;
                const stackedWindowFooter = this.getParentStackedWindowFooter();
                
                if(footer) {
                    maxTableheight = maxTableheight - footer.offsetHeight;
                }

                if(bottom) {
                    maxTableheight = maxTableheight - bottom.offsetHeight;
                }

                if(stackedWindowFooter) {
                    maxTableheight = maxTableheight - stackedWindowFooter.offsetHeight;
                }
                // console.log(maxTableheight);
                if (height > maxTableheight) this.maxTableHeight = maxTableheight;
                else this.maxTableHeight = undefined;
            }, 250);
        },
        getParentStackedWindowFooter() {
            let parent = this.$parent;
            while (parent) {
                parent = parent.$parent;
                if (parent && parent.$el.querySelector('.ak-stacked-window__footer')) {
                    return parent.$el.querySelector('.ak-stacked-window__footer');
                }
            }
            return;
        },
        triggerExport(data) {
            // when we get a definition & bundle we will open a dialog with the export component
            // this component will de a batched export and provide a file to download
            this.exportDefinition = data.definitionId;
            this.exportBundle = data.bundleId;
        },
        // after the user closes/dowloads the document we reset the definition and bundle
        resetExport() {
            this.exportDefinition = null;
            this.exportBundle = null;
        }
    },
    watch: {
        dataFetchParams: {
            deep: true,
            handler() {
                if (this.preventAutoRefresh) return;
                if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
                this.debounceTimeout = setTimeout(() => this.refresh(), 300);
            }
        },
        "$route": {
            handler($route) {
                this.baseGridUri = `/${this.bundle}/${this.definition}/`;
                if ($route.matched.length === 1) this.refresh();
                this.refreshParam++;
            }
        },
        isUpdating: {
            handler(val) {
                this.$emit('loading', val);
            }
        },
        'selection.idsSelected': {
            handler() {
                // trow event when the selection is changed
                this.$emit('selectionChanged', this.selection.idsSelected);
            }
        }
    },
    created() {
        this.baseGridUri = `/${this.bundle}/${this.definition}/`;
        this.selection.idsSelected = this.idsSelected;

        this.refresh();
    }
}, 'grid/ak-data-table.html');