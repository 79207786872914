Vue.asyncComponent('ak-errors', {
    name: 'AkErrors',
    data() {
        return {
            errors: this.$errors,
        }
    },
    methods: {
        removeError(key) {
            this.$errors.splice(key, 1);
        }
    }
}, 'layout/ak-errors.html');