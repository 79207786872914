const formElementMixin = {
	props: {
		id: {
			type: String,
			default: '',
			required: true,
		},
		title: {
			type: String,
			default: '',
			required: false,
		},
		helpText: {
			type: String,
			default: '',
			required: false,
		},
		error: {
			type: String,
			default: '',
			required: false,
		},
		hasError: {
			type: Boolean,
			required: true,
		},
		value: {
			type: String|Array|Number,
			required: false,
		},
		readOnly: {
			type: Boolean,
			required: true,
		},
		isEmpty: {
			type: Boolean,
			required: true,
		},
		required: {
			type: Boolean,
			required: true,
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        }
	},
	computed: {
		currentValue: {
			get() {
				return this.value;
			},
            set(e) {
				this.$emit('input', e);
				this.$emit('updateState', this.formatStateOutput(e));
			}
		}
	},
	methods: {
		// add a method where we can overwrite the output for state handling
		// see an example in AkRelation
		formatStateOutput(e) {
			return e;
		}
	},
	created() {
		this.currentValue = this.value;
	}
}

export default formElementMixin;