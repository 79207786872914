import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-wysiwyg', {
    mixins: [FormElementMixin],
    props: {
        configOptions: {
            type: Object|Array,
            required: true
        },
    },
}, 'form/controls/ak-wysiwyg.html');