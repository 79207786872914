Vue.asyncComponent('ak-chip', {
    props: {
        title: {
            type: String,
            default: '',
            required: false,
        },
        description: {
            type: String,
            default: '',
            required: false,
        },
        color: {
            type: String,
            default: 'accent',
            required: false,
        }
    }
}, 'layout/ak-chip.html');