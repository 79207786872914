Vue.asyncComponent('ak-button', {
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
        },
        confirmTitle: {
            type: String,
            required: false,
        },
        confirmText: {
            type: String,
            required: false,
        },
        href: {
            type: String,
            required: false,
            default: ''
        },
        target: {
            type: String,
            required: false,
            default: '_self'
        },
        enableConfirm: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        disableBackground: {
            type: Boolean,
            required: false,
            default: false
        },
        colorMode: {
            type: String,
            required: false,
            default: ''
        },
        size: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            showConfirm: false,
        }
    },
    methods: {
        click(e) {
            // If a href is set, only use that.
            if (this.href) {
                window.open(this.href, this.target);
                return;
            }

            if (this.enableConfirm) {
                this.showConfirm = true;
            } else {
                this.$emit('click', e);
            }
        },
        confirm(e) {
            this.$emit('click', e);
        }
    },
}, 'layout/ak-button.html');