Vue.asyncComponent('ak-modal', {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        showTitle: {
            type: Boolean,
            required: false,
            default: true
        },
        content: {
            type: String,
            required: false,
            default: ''
        },
        showContent: {
            type: Boolean,
            required: false,
            default: true
        },
        confirmText: {
            type: String,
            required: false,
            default: 'Agree'
        },
        cancelText: {
            type: String,
            required: false,
            default: 'Disagree'
        },
        active: {
            type: Boolean,
            required: true,
            default: false
        },
        persistent: {
            type: Boolean,
            required: false,
            default: true
        },
        showButtons: {
            type: Boolean,
            required: false,
            default: true
        },
        maxWidth: {
            type: String,
            required: false,
            default: '80%'
        },
        width: {
            type: String,
            required: false,
            default: 'auto'
        }
    },
    methods: {
        onCancel() {
            this.$emit('onCancel');
        },
        onConfirm() {
            this.$emit('onConfirm');
        }
    }
}, 'layout/ak-modal.html');