Vue.asyncComponent('ak-form', {
	props: {
		definition: {
			type: String,
			required: true
		},
		bundle: {
			type: String,
			required: true
		},
		objectId: {
			type: String,
			required: false,
			default: 'new'
		},
		relationString: {
			type: String,
		},
		baseUriControls: {
			type: String,
			required: false,
			default() {
				return this.$appKitUrl() + '/' + this.bundle + '/' + this.definition;
			},
		}
	},
    data() {
        return {
			title: '',
			descr: '',
			activeTab: null,
			loading: false,
			form: {},
			baseUri: '/' + this.bundle + '/' + this.definition,
			formValues: {},
			widgetGroups: {},
			ungroupedWidgets: [],
			ungroupedWidgetsErrors: false,
			states: {
				visibility: {}
			}
        }
    },
    methods: {
		async getForm(formData) {
			let param = {};
			this.loading = true;

			if(this.objectId != 'new') param.id = this.objectId;
			try {
				let result;

				if (!formData) result = await this.$get(this.baseUri, param);
                else result = formData;

				this.loading = false;
				this.form = result.form;
				this.title = result.title ? result.title : this.title;
				this.descr = result.descr ? result.descr : this.descr;
				
				// this.bundle = result.bundle;
			} catch (error) {
				console.log(error);
            }

            this.createWidgetGroups();

			// if the submit was unsuccesfull & we have groups
			// set the active tab to the first tab where errors are pressent
			if(formData && !formData.success && this.form.layout.groups !== null) {
				this.activeTab = this.firstWidgetGroupIdWithError();
			}
		},
        async submit(e) {
			// prevent the page from refreshing when a user enters in an input field
			e.preventDefault();

			this.loading = true;
			const path = `${this.baseUri}/post`;
			const body = { "formValues": { ...this.formValues }};
			let param = {};
			if(this.objectId != 'new') param.id = this.objectId;

			try {
				const result = await this.$post(path, body, param);
				this.loading = false;
				if (result.success) this.$handleActions(result.todos, this);
				else this.getForm(result);

				this.$emit('submit');
			} catch (error) {
				console.log(error);
			}
        },
		firstWidgetGroupIdWithError() {
			for (const [id, group] of Object.entries(this.widgetGroups)) {
				if(group.hasError) {
					return id;
				}
			}

			return 'ungrouped';
		},
        handleWidgetInput(id, data) {
			this.formValues[id] = data;

			// if we need to show an alert for unsaved changes we need to check if there are any
			if(this.form.alertUnsavedChanges && this.form.alertUnsavedChanges.enabled) {
				this.checkIfFormValuesChanged()
			}
		},
		checkIfFormValuesChanged() {
			let changed = false;

			Object.keys(this.formValues).forEach( key => {
				const initialValue = this.form.widgets.find(widget => widget.id == key).formControl.value;

				// check if the value is an object if so we need to compare deep
				if (typeof this.formValues[key] === 'object' && this.formValues[key] !== null) {
					if (initialValue && !this.deepEqual(this.formValues[key], initialValue)) {
						changed = true;
					}
				} else if(this.formValues[key] !== initialValue) {
					changed = true;
				}
			});

			this.$emit('alertUnsavedChanges', {showAlert: changed, message: this.form.alertUnsavedChanges.message});
		},
		deepEqual(object1, object2) {
			const keys1 = Object.keys(object1);
			const keys2 = Object.keys(object2);
			if (keys1.length !== keys2.length) {
				return false;
			}
			for (const key of keys1) {
				const val1 = object1[key];
				const val2 = object2[key];
				const areObjects = this.isObject(val1) && this.isObject(val2);
				if (
						areObjects && !this.deepEqual(val1, val2) ||
						!areObjects && val1 !== val2
				) {
					return false;
				}
			}
			return true;
		},
		isObject(object) {
			return object != null && typeof object === 'object';
		},
		widgetAttributes(widget) {
			let attributes = {
				...widget.formControl,
				...widget.attributes,
				baseUri: this.baseUriControls,
				relationString: this.relationString,
				currentObjectId: this.objectId
			};

			Object.keys(widget).forEach(property => {
				// add other props from the widgets
				if(! ['formControl', 'attributes','slot'].includes(property) && widget[property] !== null) {
					attributes[property] = widget[property];
				}
			});

			return attributes;
		},
		widgetListener(id) {
			let out = {};

			// check if we have form syncs for this control
			if(this.form.syncs && this.form.syncs[id]) {
				this.form.syncs[id].forEach(event => {
					// add th sync form function for each listener
					out[event] = this.syncForm.bind(null, id, event);
				});
			}

			return out;
		},
		async syncForm(id, event) {
			const path = `${this.baseUri}/sync`;
			const body = {
				id: id,
				event: event,
				values: this.formValues
			};
			let param = {};

			try {
				const result = await this.$post(path, body, param);
				this.updateFormValues(result);
			} catch (error) {
				console.log(error);
			}
		},
		updateFormValues(newValues) {
			for(let id in newValues) {
				// find the widget
				let widget = this.form.widgets.find(widget => widget.id == id);
				//set the new value for the widget
				widget.formControl.value = newValues[id];
				this.handleWidgetInput(id, newValues[id]);
			}
		},
		getInputWidth(id) {
			if (this.form.layout.width[id]) return this.form.layout.width[id];
			else return 12;
		},
		// Extract groups from the form data
		createWidgetGroups() {
			if (this.form.layout.groups == null) {
				this.ungroupedWidgets = this.form.widgets;
				return;
			}

			let widgetsSelected = [];
			this.form.layout.groups.forEach(group => {
				this.widgetGroups[group.id] = { ...group };
				this.widgetGroups[group.id].widgets = this.form.widgets.filter(x => group.widgetIds.includes(x.id));
				this.widgetGroups[group.id].hasError = this.widgetGroups[group.id].widgets.some(widget => widget.formControl.hasError === true);
				widgetsSelected = [...widgetsSelected, ...group.widgetIds];
            });

			this.ungroupedWidgets = this.form.widgets.filter(x => !widgetsSelected.includes(x.id));
            this.ungroupedWidgetsErrors = this.ungroupedWidgets.some(widget => widget.formControl.hasError === true);
		},
		// Apply the states, this function is run after every input, only validates the changed input
		applyStates(id, data) {
			// when we don't have a form state we stop here
			if (!this.form.states || this.form.states.length === 0) return;

			// if we diden't get a widget id we quit here
			if (!id) return;

			// loop over all the states to check if there are states for the current widget
			this.form.states.forEach(state => {
				let stateFulfilled = [];

				// if the state doesn't contain a condition for the current widget we skip
				if ( !state.conditions.filter(condition => condition[0] == id).length ) {
					return;
				}

				state.conditions.forEach((condition, i) => {
					const widgetId = condition[0];
					const value = condition[1];
					const operator = condition[2];
					const currentValue = data;

					// check if the state is for the current widget
					if (widgetId !== id) return;

					// check if the provided condition matches anything
					if (this.$operationHandler(currentValue, operator, value).result()) {
						// we active the state and we will handle the needed action('s) ( change visibility / change value / refresh widget(s))
						stateFulfilled.push(true);
					} else {
						stateFulfilled.push(false);
					}
				});

				if (!stateFulfilled.includes(false) && stateFulfilled.length !== 0) {
					this.activateState(state)
				}
			});
		},
		activateState(state) {
			// check if we need to change the visibility of a widget for this state
			if (state.visibility) {
				this.states.visibility = { ...this.states.visibility, ...state.visibility };
			}

			// check if we need to change a value of a widget for this state
			if (state.changeValue) {
				this.formValues = {...this.formValues, ...state.changeValue};
			}

			// check if we need to do a refresh of a widget for this state
			if(state.refresh) {
				this.refreshWidgets(state.refresh);
			}
		},
		async refreshWidgets(refreshWidgets) {
			const result = await this.$post(`${this.baseUri}/form-state`, this.formValues);

			// now that we have the updated widgets
			refreshWidgets.forEach(refreshWidgetId => {
				// get the key of the widget we need to udate
				const key = this.form.widgets.findIndex(widget => widget.id === refreshWidgetId);
				// get the new widget
				const updatedWidget = result.find(widget => widget.id === refreshWidgetId);

				Vue.set(this.form.widgets, key, updatedWidget);
			});

			this.createWidgetGroups();
		},
		// If visibility is defined in data, return the value. Standard value is "null"
		checkVisibility(id) {
			if (this.states.visibility[id] !== undefined) return this.states.visibility[id];
			return true;
		},
		async validateValue(id) {
			if (!id) return;
			const path = `${this.baseUri}/validateControl`;
			const validateData = {
				"widgetId": id,
				"formValues": { ...this.formValues }
			};

			try {
				const result = await this.$post(path, validateData);
				let widget = this.form.widgets.find(x => x.id === id);
				widget.formControl = { ...widget.formControl, ...result[id] };

				// Do something
			} catch (error) {
				console.log(error);
			}
		},
	},
	created() {
		this.getForm();
	}
}, 'form/ak-form.html');