import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-checkbox', {
    mixins: [FormElementMixin],
    data() {
        return {
            selected: false
        }
    },
    watch: {
        selected() {
            this.currentValue = this.selected;
        }
	},
	created() {
        this.selected = this.value;
	}
}, 'form/controls/ak-checkbox.html');