Vue.asyncComponent('ak-drawer', {
    data: () => {
        return {
            show: false,
        }
    },
    props: {
        showDrawer: {
            type: Boolean,
            required: false,
            default: ''
        },
        logoPath: {
            type: String,
            required: false,
            default: ''
        },
        pinned: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        showDrawer(val) {
            this.show = val;
        },
        show(val) {
            if(val == false) {
                this.$emit('close');
            }
        }
    }
}, 'wrappers/ak-drawer.html');