// UTILS
// Loading Async components
import './utils/async-component';

// Helpers
import {helpersPlugin} from './utils/helpers';
Vue.use(helpersPlugin);

// Directives
import './utils/directives/click-outside';

// Settings
import {settingsPlugin} from './utils/settings';
Vue.use(settingsPlugin);

// HTTP client
import dataManager from './utils/data-manager';
Vue.use(dataManager);

// Polyglot translations
import { fetchDictionary, translatePlugin } from './utils/polyglot';
Vue.use(translatePlugin);

// Filters
import {formatPlugin} from './utils/vue-filters';
Vue.use(formatPlugin);

// Action handler
import actionHandler from './utils/action-handler';
Vue.use(actionHandler);

import moment from 'moment';
Object.defineProperty(Vue.prototype, '$moment', { value: moment });

// LAYOUT

// COMPONENTS
// Grid
import './components/grid/AkDataTable';
import './components/grid/layout/AkGridFab';
import './components/grid/AkFilters';
import './components/grid/AkDataTableBody';
import './components/grid/AkRelationDataTable';
import './components/grid/AkExport';
import './components/grid/AkExportDialog';
import './components/grid/layout/AkWidgetDialog';
import './components/grid/layout/AkPageJumper'; 
import './components/grid/layout/AkWidgetList';

// Layout
import './components/layout/AkModal';
import './components/layout/AkButton';
import './components/layout/AkLink';
import './components/layout/AkHtml';
import './components/layout/AkChip';
import './components/layout/AkFab';
import './components/layout/AkConfirm';
import './components/layout/AkSnackbar';
import './components/layout/AkMenuItem';
import './components/layout/AkErrors';

// Wrappers
import './components/wrappers/AkWidget';
import './components/wrappers/AkNavigation';
import './components/wrappers/AkDrawer';
import './components/wrappers/AkMenu';
import './components/wrappers/AkRelationResolver';

// Form
import './components/form/AkForm';
import './components/form/controls/AkTextArea';
import './components/form/controls/AkInputText';
import './components/form/controls/AkInputPassword';
import './components/form/controls/AkNumber';
import './components/form/controls/AkSelect';
import './components/form/controls/AkDatePicker';
import './components/form/controls/AkCheckbox';
import './components/form/controls/AkUpload';
import './components/form/controls/AkTimePicker';
import './components/form/controls/AkRelation';
import './components/form/controls/AkCheckboxes';
import './components/form/controls/AkRadio';
import './components/form/controls/AkSlug';
import './components/form/controls/AkWysiwyg';

// Utilities
import './components/utilities/AkInViewport';
import './components/utilities/AkStackedWindow';
import './components/utilities/T';

// DEPENDENCIES @todo: make this work
import draggable from "vuedraggable";
Vue.component(draggable);

// Vue Upload component
import VueUploadComponent from "vue-upload-component";
Vue.component('file-upload', VueUploadComponent);

// Redactor
import './assets/vendor/redactor/redactor.min.js';
import './assets/vendor/redactor/_plugins/video/video.min.js';
import './assets/vendor/redactor/_plugins/alignment/alignment.min.js';
import './assets/vendor/redactor/_plugins/fullscreen/fullscreen.min.js';
import './assets/vendor/redactor/_plugins/table/table.min.js';
import './assets/vendor/redactor/_plugins/imagemanager/imagemanager.min.js';
import 'vue-redactor/vue-redactor.js';

class AppKit {
    init(app) {
        this.app = app;
        this.assignCssVariables();
        this.initPolyglot();
    }

    getApp() {
        return this.app
    }
    
    async initPolyglot() {
        let baseTranslations = '';
        let translationUrl = '';

        // Log the config file in dev mode
        if (process.env.NODE_ENV === 'development') console.log('DEV: Config file: ', appKitSettings);
        
        Vue.prototype.$moment.locale('nl');
        let lang = Vue.prototype.$getSetting('lang');
        
        try {
            if (Vue.prototype.$getSetting('paths.translations')) {
                translationUrl = Vue.prototype.$getSetting(`paths.translations.${lang}`);
                await fetchDictionary(translationUrl);
            } else if (Vue.prototype.$assetUrl()) {
                baseTranslations = `${Vue.prototype.$assetUrl()}/translations/${lang}.json`;
                await fetchDictionary(baseTranslations);
            }
        } catch (error) {
            console.log(error);
        }
    }
    assignCssVariables() {
        let styles = Vue.prototype.$getSetting('styles');

        Object.entries(styles).forEach(this.assignCssVar, this);
        Object.entries(styles).forEach(this.setVuetifyVars, this);

        let assets = Vue.prototype.$getSetting('assets');
        Object.entries(assets).forEach(this.assignCssVar, this);
    }

    setVuetifyVars([key, value]) {
        var name = key.replace('color','').toLowerCase();

        // check if value is a property of the theme
        if(this.app.$vuetify.theme.themes.light.hasOwnProperty(name)){
            this.app.$vuetify.theme.themes.light[name] = value;
        }
    }

    assignCssVar([key, value]) {
        // convert camelCase to kebab-case
        key = key.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);

        // check if the value is a url
        if(value.indexOf('http') != -1 || value.indexOf('https') != -1 ) {

            value = 'url("' + value + '")';
        }

        // register the css var
        document.documentElement.style.setProperty('--' + key, value);
    }
}

window.AppKit = new AppKit();