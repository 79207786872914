const handleRoute = async (action) => {
    const app = AppKit.getApp();
    if (action.route.charAt(0) === '#') {
        const route = action.route.substr(1);

        if (app.$route.path === route) {
            app.$router.push({
                path: route,
                query: {
                    t: Date.now()
                }
            });
        } else {
            app.$router.push({
                path: route
            });
        }
    }
    else window.open(action.route, action.target);
};

// Never gonna give you up, never gonna let you down, never gonna run around and desert you
// const handleRoute = async (action) => {
//     const app = AppKit.getApp();
//     if (action.route.charAt(0) === '#') {
//         const route = action.route.substr(1);

//         if (app.$route.path === route) {
//             app.$router.go(app.$router.currentRoute);
//         } else {
//             app.$router.push({
//                 path: route
//             });
//         }
//     }
//     else window.open(action.route, action.target);
// };

const addGlobalMessage = (message) => {
    AppKit.getApp().globalMessages.push({
        show: true,
        text: message.title,
        level: message.level
    });
};

const removeGlobalMessage = (message) => {
    AppKit.getApp().globalMessages = AppKit.getApp().globalMessages.filter(el => el.text != message.text);
};

const handleEvent = async (action, component) => {
    component.$emit(action.eventName, action.eventData);
};

const handleRefresh = async (action, component) => {
    component.$emit('refresh', action.eventData);
};

// Creates a promise that resolves after executing actions
const handleActions = async (actions, component) => {
    // Function should never be called without actions, but still check for it.
    if (actions.length === 0) return;

    // Globally execute global functions (route and message)
    actions.forEach(async action => {
        switch (action.type) {
            case 'route':
                await handleRoute(action);
                break;
            case 'message':
                await addGlobalMessage(action);
                break;
            case 'event':
                await handleEvent(action, component);
                break;
            case 'refresh':
                await handleRefresh(action, component);
                break;
            default:
                break;
        }
    });

    // Return the actions that need to be performed within the component (refresh for now)
    return actions.filter(x => x.type === 'refresh');
}

const actionHandler = {
    install: (Vue) => {
        Vue.prototype.$handleActions = async (actions, component) => handleActions(actions, component);
        Vue.prototype.$removeGlobalMessage = async (actions) => removeGlobalMessage(actions);
        Vue.prototype.$addGlobalMessage = async (actions) => addGlobalMessage(actions);
    }
}

export default actionHandler;