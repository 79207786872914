Vue.asyncComponent('ak-menu-item', {
    name: '',
    data: () => {
        return {
            hasChildMenu: false,
            currentPath: null,
        }
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        href: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: false,
        },
        target: {
            type: String,
            required: false,
            default: '_self'
        }
    },
    computed: {
        active() {
            return this.currentPath == this.href;
        },
        isFullUrl() {
            return this.href.substring(0, 4) === 'http';
        }
    },
    methods: {
        childMenu(object) {
             return object.$children.find(
                child => child.$options.name === "ak-menu"   
            );
        },
        onClickMenuItem(event, item) {
            let childMenu = this.childMenu(this);
            
            if (childMenu) {
                childMenu.show = true;
                event.preventDefault();
            }
        },
        closeAllSubMenus() {
            // This seems overkill and is disabled for now. If necessary we can add a toggle?
            return;
            // when the users clicks on a link we need to close all the parent menu's except for the last one
            let parent = this.$parent;
            let parentMenus = [];

            while (parent) {
                if (parent && parent.$options.name == 'ak-menu') {
                    parent.show = false;
                    parentMenus.push(parent);
                }

                parent = parent.$parent;
            }
            
            // always show the root menu
            parentMenus.pop().show = true;

            this.$emit('click');
        }
    },
    watch: {
        "$route": {
            handler(val) {
                this.currentPath = this.$router.currentRoute.path
            }
        }
    },
    mounted() {
        this.hasChildMenu = this.childMenu(this) != undefined;
        this.currentPath = this.$router.currentRoute.path
    }
}, 'layout/ak-menu-item.html');