Vue.asyncComponent('ak-data-table-body', {
    data() {
        return {
            idSelected: this.selection.idsSelected[0],
            showModal: false,
            showContextualWidgets: false,
            contextualWidgets: {
                list: []
            },
            objectIds: [],
            isOverflowing: false,
            showSnackbar: false,
            snackbarMessage: '',
            autoSelection: false,
            dragId: null,
            showDragContext: false,
            dragContextPosition: {
                x: 0,
                y: 0
            },
            dragContextTimeout: null,
            dragging: null,
            activeRowId: '',
        }
    },
    props: {
        columns: {
            type: Array,
            required: true,
            default: () => []
        },
        dataObjects: {
            type: Array,
            required: true,
            default: () => {}
        },
        selectables: {
            type: Object,
            required: true,
            default: () => {}
        },
        selection: {
            type: Object,
            required: true,
            default: () => {}
        },
        isUpdating: {
            type: Boolean,
            required: false,
            default: false
        },
        sortingEnabled: {
            type: Boolean,
            required: false,
			default: false
		},
		pageJumper: {
			type: Object,
			required: false,
			default: () => {}
		},
        widgetCallbackUri: {
            type: String,
            required: true
        },
        maxTableHeight: {
            type: Number,
            required: false
        },
        noResultString: {
            type: String,
            required: false,
            default: 'Geen resultaten gevonden'
        }
    },
    computed: {
        mutableDataObjects: {
            get() {
                return this.dataObjects;
            },
            set(e) {
                this.$emit('changeList', e);
            }
        },
        widgetsInRow() {
            return this.dataObjects.some((row) => {
                return row.hasOwnProperty('widgets');
            });
        },
        totalColumnCount() {
            let columnCount = this.columns.length
        
            if(this.sortingEnabled) {
                columnCount++;
            }

            if(this.selectables.canSelectAll || this.selectables.canSelectOneByOne) {
                columnCount++;
            }
            
            if(this.widgetsInRow) {
                columnCount++;
            }
            
            return columnCount;
        },
        dragContextOptions() {
			const index = this.dataObjects.findIndex(x => x.id === this.dragId);

			if (this.dataObjects.length === 1 || index === -1) return {};
			let options = null;

            switch (index) {
                case 0 && this.pageJumper.activePage === 1: 
                    options = [
                        { title: 'To bottom', value: 'bottom' }
					];
					break;
                case this.dataObjects.length - 1: case this.pageJumper.activePage === this.pageJumper.numberOfPages:
                    options = [
                        { title: 'To top', value: 'top' }
					];
					break;
                default:
                    options = [
                        { title: 'To top', value: 'top' },
                        { title: 'To bottom', value: 'bottom' }
					];
					break;
			}

			if (!this.$getSetting('config.infiniteScroll') && this.pageJumper.activePage !== 1 && index === 0) {
				options.splice(1, 0, { title: 'Up 1', value: 'up'} );
			}
			if (index === this.pageJumper.countPerPage - 1 && this.pageJumper.activePage !== this.pageJumper.numberOfPages) {
				options.splice(1, 0, { title: 'Down 1', value: 'down' });
			}
			
			return options;
        }
    },
    methods: {
        showSelectAllModal() {
            if (this.selection.allSelected) this.selectAll();
            else if (this.autoSelection) {
                this.selection.idsSelected = [];
                this.autoSelection = false;
            }
            else this.showModal = true;
        },
        message() {
            console.log('hier');
        },
        selectAll() {
            this.showModal = false;
            this.selection.allSelected = !this.selection.allSelected;
            if (this.selection.allSelected && !this.autoSelection) this.selection.idsSelected = this.objectIds;
            else this.selection.idsSelected = [];
        },
        selectMaximum() {
            this.showModal = false;
            this.autoSelection = true;
            this.selection.idsSelected = this.objectIds.slice(0, this.selectables.maximum);
        },
        isRowSortedBy(key) {
            return this.columns.find(x => x.id === key).isSortedBy;
        },
        findColumnTitle(key) {
            return this.columns.find(x => x.id === key).title;
        },
        applyColumnFormatting(key, value) {
            const formatting = this.columns.find(x => x.id === key).formatting;

            // if there is no formatting set in for this value just return the value
            if(typeof formatting == 'undefined') {
                return value;
            }
            
            // else we apply the formatting
            return this.$applyFormatting(value, formatting);
        },
        sortColumn(column) {
            if (this.isUpdating) return;
            this.$emit('sortColumn', column);
        },
        showContextualDialog(e, id) {
			this.contextualWidgets = e;
			this.activeRowId = id;
            this.showContextualWidgets = true;
        },
        closeContexualDialog() {
			this.showContextualWidgets = false;
			this.activeRowId = '';
        },
        handleEdit(data) {
            this.$emit('edit', data);
        },
        handleDelete(data) {
			if (this.isUpdating) return;
			this.closeContexualDialog();
            this.$emit('delete', data);
        },
        dragStart(e) {
            this.dragId = e.item.id;
            this.showDragContext = false;
            this.dragging = e.item.id;
            clearTimeout(this.dragContextTimeout);
        },
        dragEnd() {
			this.$emit('sort', { id: this.dragId, instruction: 'drag' });
            this.dragId = '';
			this.dragging = null;
			
        },
        draghandleClickContext(id, e) {
            this.dragContextTimeout = setTimeout(() => {
                this.dragId = id;
                this.dragContextPosition =  {
                    x: e.clientX,
                    y: e.clientY
                };
                this.showDragContext = true;
            }, 100);
        },
        changePosition(to) {
            this.showDragContext = false;
			
			this.$emit('sort', { id: this.dragId, instruction: to });
		},
        checkOverflow() {
            let table = this.getTable();

            this.$refs.table.$el.classList.remove('ak-table--overflow-right');
            this.$refs.table.$el.classList.remove('ak-table--overflow-left');

            // check if there is horizontal overflow
            if(table.offsetWidth >= table.scrollWidth) {
                return;
            }

            // check if we have overflow right
            if((table.offsetWidth + table.scrollLeft) < table.scrollWidth) {
                this.$refs.table.$el.classList.add('ak-table--overflow-right');
            }

            // if we have overflow left
            if(table.scrollLeft > 0) {
                this.$refs.table.$el.classList.add('ak-table--overflow-left');
            }
        },
        getTable() {
            // console.log(this.$refs.table);
            try {
                return this.$refs.table.$el.querySelector(".v-data-table__wrapper");
            } catch (error) {
                return undefined;
            }
            
        },
        /**
         * Get the contextual title for a specififc row
         * @param rowId
         * @returns {null|*}
         */
        getContextualTitle(rowId) {
            if ( ! rowId) {
                return null;
            }

            return this.dataObjects.find(dataObject => dataObject.id === rowId).title;
        }
    },
    watch: {
        dataObjects(e) {
            this.objectIds = this.dataObjects.filter(x => x.canSelect === true).map(x => x.id);
            if (this.selection.allSelected) this.selection.idsSelected = this.objectIds;
        },
        'selection.idsSelected': {
            handler(e) {
                if (e.length === this.selectables.maximum + 1) {
                    e.pop();
                    this.snackbarMessage = this.$t(`Maximaal ${this.selectables.maximum} tegelijk selecteerbaar.`);
                    this.showSnackbar = true;
                }

            }
        },
        idSelected() {
            // if we the maximum selection is 1 we use radio buttons and we sync them with the selection.idsSelected
            this.selection.idsSelected = [this.idSelected];
        },
        showDragContext(e) {
            if (!e) this.dragId = null;
        }
    },
    mounted()  {
        this.$nextTick(() => {
            let table = this.getTable();
            table.addEventListener('scroll', this.checkOverflow);
            window.addEventListener('resize', this.checkOverflow);

            const observer = new MutationObserver(this.checkOverflow);
            observer.observe(table, { childList: true, subtree: true });
        });
    }
}, 'grid/ak-data-table-body.html');