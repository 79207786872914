Vue.asyncComponent('ak-confirm', {
    name: 'AkConfirm',
    props: {
        value: Boolean,
        confirmTitle: String,
        confirmText: String
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input',val);
            }
        }
    },
    methods: {
        confirmed() {
            this.show = false;
            this.$emit('confirm');
        },
        canceled() {
            this.show = false;
            this.$emit('cancel');
        }
    }
}, 'layout/ak-confirm.html');