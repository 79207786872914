Vue.asyncComponent('ak-link', {
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
        },
        href: {
            type: String,
            required: false,
            default: ''
        },
        target: {
            type: String,
            required: false,
            default: '_self'
        }
    },
    methods: {
        click(e) {
            // If a href is set, only use that.
            if (this.href) {
                window.open(this.href, this.target);
                return;
            }

            this.$emit('click', e);
        }
    },
}, 'layout/ak-link.html');